
// @ts-nocheck


export const localeCodes =  [
  "ru",
  "en"
]

export const localeMessages = {
  "ru": [{ key: "../vueI18n/lang/ru.json", load: () => import("../vueI18n/lang/ru.json" /* webpackChunkName: "locale__usr_src_nuxt_app_vueI18n_lang_ru_json" */), cache: true }],
  "en": [{ key: "../vueI18n/lang/en.json", load: () => import("../vueI18n/lang/en.json" /* webpackChunkName: "locale__usr_src_nuxt_app_vueI18n_lang_en_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../vueI18n/i18n.config.ts?hash=bf5395d2&config=1" /* webpackChunkName: "__vueI18n_i18n_config_ts_bf5395d2" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./vueI18n/i18n.config.ts",
  "locales": [
    {
      "code": "ru",
      "name": "RU",
      "files": [
        "vueI18n/lang/ru.json"
      ]
    },
    {
      "code": "en",
      "name": "EN",
      "files": [
        "vueI18n/lang/en.json"
      ]
    }
  ],
  "defaultLocale": "ru",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "./vueI18n/lang",
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "ru",
      "name": "RU",
      "files": [
        {
          "path": "vueI18n/lang/ru.json"
        }
      ]
    },
    {
      "code": "en",
      "name": "EN",
      "files": [
        {
          "path": "vueI18n/lang/en.json"
        }
      ]
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

