import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPrimeCalendar, LazyPrimeDropdown, LazyPrimeListbox, LazyPrimeSelectButton, LazyPrimeDialog, LazyPrimeOverlayPanel, LazyPrimePanelMenu, LazyPrimeSkeleton, LazyPrimeProgressSpinner } from '#components'
const lazyGlobalComponents = [
  ["PrimeCalendar", LazyPrimeCalendar],
["PrimeDropdown", LazyPrimeDropdown],
["PrimeListbox", LazyPrimeListbox],
["PrimeSelectButton", LazyPrimeSelectButton],
["PrimeDialog", LazyPrimeDialog],
["PrimeOverlayPanel", LazyPrimeOverlayPanel],
["PrimePanelMenu", LazyPrimePanelMenu],
["PrimeSkeleton", LazyPrimeSkeleton],
["PrimeProgressSpinner", LazyPrimeProgressSpinner],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
