import * as Sentry from "@sentry/vue"

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  // eslint-disable-next-line require-await
  async setup (nuxtApp) {
    const vueApp = nuxtApp.vueApp
    const { public: { sentry } } = useRuntimeConfig()

    if (sentry.env === 'development' || sentry.env === 'stage' || sentry.env === 'production') {
      Sentry.init({
        app: vueApp,
        dsn: sentry.sentryDsn ?? null,
        environment: sentry.env,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["https://develop.m3.sale/", "https://stage.m3.sale/", "https://m3.sale/"],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      })
    }
  }
})