import { default as change_45password159PxjrMDCMeta } from "/usr/src/nuxt-app/pages/account-customer/change-password.vue?macro=true";
import { default as chatPlT2DzWB0aMeta } from "/usr/src/nuxt-app/pages/account-customer/chat.vue?macro=true";
import { default as favoritesJHVqC8sxd4Meta } from "/usr/src/nuxt-app/pages/account-customer/favorites.vue?macro=true";
import { default as _91uuid_93JlGF93BEGWMeta } from "/usr/src/nuxt-app/pages/account-customer/layout/[uuid].vue?macro=true";
import { default as add_45new_45layoutEIzBKvFa1YMeta } from "/usr/src/nuxt-app/pages/account-customer/layout/add-new-layout.vue?macro=true";
import { default as add_45layoutNaiEpM98M8Meta } from "/usr/src/nuxt-app/pages/account-customer/layouts/add-layout.vue?macro=true";
import { default as indexrMKfwa7sl4Meta } from "/usr/src/nuxt-app/pages/account-customer/layouts/index.vue?macro=true";
import { default as purchasesbFXoCQGb4cMeta } from "/usr/src/nuxt-app/pages/account-customer/purchases.vue?macro=true";
import { default as requests9YDiJ8ThaTMeta } from "/usr/src/nuxt-app/pages/account-customer/requests.vue?macro=true";
import { default as settingsM1Cy8us7nbMeta } from "/usr/src/nuxt-app/pages/account-customer/settings.vue?macro=true";
import { default as change_45password9FYHFlwfq4Meta } from "/usr/src/nuxt-app/pages/account-seller/change-password.vue?macro=true";
import { default as chat6jQFZ6fTLiMeta } from "/usr/src/nuxt-app/pages/account-seller/chat.vue?macro=true";
import { default as organizationH1zW4AAo00Meta } from "/usr/src/nuxt-app/pages/account-seller/organization.vue?macro=true";
import { default as requestsbfjJFciJK6Meta } from "/usr/src/nuxt-app/pages/account-seller/requests.vue?macro=true";
import { default as safetyNP4YJjbCkAMeta } from "/usr/src/nuxt-app/pages/account-seller/safety.vue?macro=true";
import { default as statlXIUo6fX65Meta } from "/usr/src/nuxt-app/pages/account-seller/stat.vue?macro=true";
import { default as login6Y49Qe3BVbMeta } from "/usr/src/nuxt-app/pages/auth/login.vue?macro=true";
import { default as payment3nm6arDBdcMeta } from "/usr/src/nuxt-app/pages/auth/payment.vue?macro=true";
import { default as registration_45customerm0PMn7kbHQMeta } from "/usr/src/nuxt-app/pages/auth/registration-customer.vue?macro=true";
import { default as registration_45sellerh3H4i6pw2gMeta } from "/usr/src/nuxt-app/pages/auth/registration-seller.vue?macro=true";
import { default as reset_45passwordxjbKLbTaT9Meta } from "/usr/src/nuxt-app/pages/auth/reset-password.vue?macro=true";
import { default as send_45reset_45password_45linkY1aGuoZhvtMeta } from "/usr/src/nuxt-app/pages/auth/send-reset-password-link.vue?macro=true";
import { default as verify_45userCICNdktaqwMeta } from "/usr/src/nuxt-app/pages/auth/verify-user.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as investorsDAxxJuzdMiMeta } from "/usr/src/nuxt-app/pages/investors.vue?macro=true";
import { default as _91slug_93wkBDbFQkHIMeta } from "/usr/src/nuxt-app/pages/news/[slug].vue?macro=true";
import { default as indexAqnIhg14ssMeta } from "/usr/src/nuxt-app/pages/news/index.vue?macro=true";
import { default as sellerlX3io9K2mKMeta } from "/usr/src/nuxt-app/pages/seller.vue?macro=true";
export default [
  {
    name: change_45password159PxjrMDCMeta?.name ?? "account-customer-change-password___ru",
    path: change_45password159PxjrMDCMeta?.path ?? "/account-customer/change-password",
    meta: change_45password159PxjrMDCMeta || {},
    alias: change_45password159PxjrMDCMeta?.alias || [],
    redirect: change_45password159PxjrMDCMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/change-password.vue").then(m => m.default || m)
  },
  {
    name: change_45password159PxjrMDCMeta?.name ?? "account-customer-change-password___en",
    path: change_45password159PxjrMDCMeta?.path ?? "/en/account-customer/change-password",
    meta: change_45password159PxjrMDCMeta || {},
    alias: change_45password159PxjrMDCMeta?.alias || [],
    redirect: change_45password159PxjrMDCMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/change-password.vue").then(m => m.default || m)
  },
  {
    name: chatPlT2DzWB0aMeta?.name ?? "account-customer-chat___ru",
    path: chatPlT2DzWB0aMeta?.path ?? "/account-customer/chat",
    meta: chatPlT2DzWB0aMeta || {},
    alias: chatPlT2DzWB0aMeta?.alias || [],
    redirect: chatPlT2DzWB0aMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/chat.vue").then(m => m.default || m)
  },
  {
    name: chatPlT2DzWB0aMeta?.name ?? "account-customer-chat___en",
    path: chatPlT2DzWB0aMeta?.path ?? "/en/account-customer/chat",
    meta: chatPlT2DzWB0aMeta || {},
    alias: chatPlT2DzWB0aMeta?.alias || [],
    redirect: chatPlT2DzWB0aMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/chat.vue").then(m => m.default || m)
  },
  {
    name: favoritesJHVqC8sxd4Meta?.name ?? "account-customer-favorites___ru",
    path: favoritesJHVqC8sxd4Meta?.path ?? "/account-customer/favorites",
    meta: favoritesJHVqC8sxd4Meta || {},
    alias: favoritesJHVqC8sxd4Meta?.alias || [],
    redirect: favoritesJHVqC8sxd4Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/favorites.vue").then(m => m.default || m)
  },
  {
    name: favoritesJHVqC8sxd4Meta?.name ?? "account-customer-favorites___en",
    path: favoritesJHVqC8sxd4Meta?.path ?? "/en/account-customer/favorites",
    meta: favoritesJHVqC8sxd4Meta || {},
    alias: favoritesJHVqC8sxd4Meta?.alias || [],
    redirect: favoritesJHVqC8sxd4Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/favorites.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93JlGF93BEGWMeta?.name ?? "account-customer-layout-uuid___ru",
    path: _91uuid_93JlGF93BEGWMeta?.path ?? "/account-customer/layout/:uuid()",
    meta: _91uuid_93JlGF93BEGWMeta || {},
    alias: _91uuid_93JlGF93BEGWMeta?.alias || [],
    redirect: _91uuid_93JlGF93BEGWMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/layout/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93JlGF93BEGWMeta?.name ?? "account-customer-layout-uuid___en",
    path: _91uuid_93JlGF93BEGWMeta?.path ?? "/en/account-customer/layout/:uuid()",
    meta: _91uuid_93JlGF93BEGWMeta || {},
    alias: _91uuid_93JlGF93BEGWMeta?.alias || [],
    redirect: _91uuid_93JlGF93BEGWMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/layout/[uuid].vue").then(m => m.default || m)
  },
  {
    name: add_45new_45layoutEIzBKvFa1YMeta?.name ?? "account-customer-layout-add-new-layout___ru",
    path: add_45new_45layoutEIzBKvFa1YMeta?.path ?? "/account-customer/layout/add-new-layout",
    meta: add_45new_45layoutEIzBKvFa1YMeta || {},
    alias: add_45new_45layoutEIzBKvFa1YMeta?.alias || [],
    redirect: add_45new_45layoutEIzBKvFa1YMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/layout/add-new-layout.vue").then(m => m.default || m)
  },
  {
    name: add_45new_45layoutEIzBKvFa1YMeta?.name ?? "account-customer-layout-add-new-layout___en",
    path: add_45new_45layoutEIzBKvFa1YMeta?.path ?? "/en/account-customer/layout/add-new-layout",
    meta: add_45new_45layoutEIzBKvFa1YMeta || {},
    alias: add_45new_45layoutEIzBKvFa1YMeta?.alias || [],
    redirect: add_45new_45layoutEIzBKvFa1YMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/layout/add-new-layout.vue").then(m => m.default || m)
  },
  {
    name: add_45layoutNaiEpM98M8Meta?.name ?? "account-customer-layouts-add-layout___ru",
    path: add_45layoutNaiEpM98M8Meta?.path ?? "/account-customer/layouts/add-layout",
    meta: add_45layoutNaiEpM98M8Meta || {},
    alias: add_45layoutNaiEpM98M8Meta?.alias || [],
    redirect: add_45layoutNaiEpM98M8Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/layouts/add-layout.vue").then(m => m.default || m)
  },
  {
    name: add_45layoutNaiEpM98M8Meta?.name ?? "account-customer-layouts-add-layout___en",
    path: add_45layoutNaiEpM98M8Meta?.path ?? "/en/account-customer/layouts/add-layout",
    meta: add_45layoutNaiEpM98M8Meta || {},
    alias: add_45layoutNaiEpM98M8Meta?.alias || [],
    redirect: add_45layoutNaiEpM98M8Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/layouts/add-layout.vue").then(m => m.default || m)
  },
  {
    name: indexrMKfwa7sl4Meta?.name ?? "account-customer-layouts___ru",
    path: indexrMKfwa7sl4Meta?.path ?? "/account-customer/layouts",
    meta: indexrMKfwa7sl4Meta || {},
    alias: indexrMKfwa7sl4Meta?.alias || [],
    redirect: indexrMKfwa7sl4Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/layouts/index.vue").then(m => m.default || m)
  },
  {
    name: indexrMKfwa7sl4Meta?.name ?? "account-customer-layouts___en",
    path: indexrMKfwa7sl4Meta?.path ?? "/en/account-customer/layouts",
    meta: indexrMKfwa7sl4Meta || {},
    alias: indexrMKfwa7sl4Meta?.alias || [],
    redirect: indexrMKfwa7sl4Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/layouts/index.vue").then(m => m.default || m)
  },
  {
    name: purchasesbFXoCQGb4cMeta?.name ?? "account-customer-purchases___ru",
    path: purchasesbFXoCQGb4cMeta?.path ?? "/account-customer/purchases",
    meta: purchasesbFXoCQGb4cMeta || {},
    alias: purchasesbFXoCQGb4cMeta?.alias || [],
    redirect: purchasesbFXoCQGb4cMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/purchases.vue").then(m => m.default || m)
  },
  {
    name: purchasesbFXoCQGb4cMeta?.name ?? "account-customer-purchases___en",
    path: purchasesbFXoCQGb4cMeta?.path ?? "/en/account-customer/purchases",
    meta: purchasesbFXoCQGb4cMeta || {},
    alias: purchasesbFXoCQGb4cMeta?.alias || [],
    redirect: purchasesbFXoCQGb4cMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/purchases.vue").then(m => m.default || m)
  },
  {
    name: requests9YDiJ8ThaTMeta?.name ?? "account-customer-requests___ru",
    path: requests9YDiJ8ThaTMeta?.path ?? "/account-customer/requests",
    meta: requests9YDiJ8ThaTMeta || {},
    alias: requests9YDiJ8ThaTMeta?.alias || [],
    redirect: requests9YDiJ8ThaTMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/requests.vue").then(m => m.default || m)
  },
  {
    name: requests9YDiJ8ThaTMeta?.name ?? "account-customer-requests___en",
    path: requests9YDiJ8ThaTMeta?.path ?? "/en/account-customer/requests",
    meta: requests9YDiJ8ThaTMeta || {},
    alias: requests9YDiJ8ThaTMeta?.alias || [],
    redirect: requests9YDiJ8ThaTMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/requests.vue").then(m => m.default || m)
  },
  {
    name: settingsM1Cy8us7nbMeta?.name ?? "account-customer-settings___ru",
    path: settingsM1Cy8us7nbMeta?.path ?? "/account-customer/settings",
    meta: settingsM1Cy8us7nbMeta || {},
    alias: settingsM1Cy8us7nbMeta?.alias || [],
    redirect: settingsM1Cy8us7nbMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsM1Cy8us7nbMeta?.name ?? "account-customer-settings___en",
    path: settingsM1Cy8us7nbMeta?.path ?? "/en/account-customer/settings",
    meta: settingsM1Cy8us7nbMeta || {},
    alias: settingsM1Cy8us7nbMeta?.alias || [],
    redirect: settingsM1Cy8us7nbMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-customer/settings.vue").then(m => m.default || m)
  },
  {
    name: change_45password9FYHFlwfq4Meta?.name ?? "account-seller-change-password___ru",
    path: change_45password9FYHFlwfq4Meta?.path ?? "/account-seller/change-password",
    meta: change_45password9FYHFlwfq4Meta || {},
    alias: change_45password9FYHFlwfq4Meta?.alias || [],
    redirect: change_45password9FYHFlwfq4Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-seller/change-password.vue").then(m => m.default || m)
  },
  {
    name: change_45password9FYHFlwfq4Meta?.name ?? "account-seller-change-password___en",
    path: change_45password9FYHFlwfq4Meta?.path ?? "/en/account-seller/change-password",
    meta: change_45password9FYHFlwfq4Meta || {},
    alias: change_45password9FYHFlwfq4Meta?.alias || [],
    redirect: change_45password9FYHFlwfq4Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-seller/change-password.vue").then(m => m.default || m)
  },
  {
    name: chat6jQFZ6fTLiMeta?.name ?? "account-seller-chat___ru",
    path: chat6jQFZ6fTLiMeta?.path ?? "/account-seller/chat",
    meta: chat6jQFZ6fTLiMeta || {},
    alias: chat6jQFZ6fTLiMeta?.alias || [],
    redirect: chat6jQFZ6fTLiMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-seller/chat.vue").then(m => m.default || m)
  },
  {
    name: chat6jQFZ6fTLiMeta?.name ?? "account-seller-chat___en",
    path: chat6jQFZ6fTLiMeta?.path ?? "/en/account-seller/chat",
    meta: chat6jQFZ6fTLiMeta || {},
    alias: chat6jQFZ6fTLiMeta?.alias || [],
    redirect: chat6jQFZ6fTLiMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-seller/chat.vue").then(m => m.default || m)
  },
  {
    name: organizationH1zW4AAo00Meta?.name ?? "account-seller-organization___ru",
    path: organizationH1zW4AAo00Meta?.path ?? "/account-seller/organization",
    meta: organizationH1zW4AAo00Meta || {},
    alias: organizationH1zW4AAo00Meta?.alias || [],
    redirect: organizationH1zW4AAo00Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-seller/organization.vue").then(m => m.default || m)
  },
  {
    name: organizationH1zW4AAo00Meta?.name ?? "account-seller-organization___en",
    path: organizationH1zW4AAo00Meta?.path ?? "/en/account-seller/organization",
    meta: organizationH1zW4AAo00Meta || {},
    alias: organizationH1zW4AAo00Meta?.alias || [],
    redirect: organizationH1zW4AAo00Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-seller/organization.vue").then(m => m.default || m)
  },
  {
    name: requestsbfjJFciJK6Meta?.name ?? "account-seller-requests___ru",
    path: requestsbfjJFciJK6Meta?.path ?? "/account-seller/requests",
    meta: requestsbfjJFciJK6Meta || {},
    alias: requestsbfjJFciJK6Meta?.alias || [],
    redirect: requestsbfjJFciJK6Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-seller/requests.vue").then(m => m.default || m)
  },
  {
    name: requestsbfjJFciJK6Meta?.name ?? "account-seller-requests___en",
    path: requestsbfjJFciJK6Meta?.path ?? "/en/account-seller/requests",
    meta: requestsbfjJFciJK6Meta || {},
    alias: requestsbfjJFciJK6Meta?.alias || [],
    redirect: requestsbfjJFciJK6Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-seller/requests.vue").then(m => m.default || m)
  },
  {
    name: safetyNP4YJjbCkAMeta?.name ?? "account-seller-safety___ru",
    path: safetyNP4YJjbCkAMeta?.path ?? "/account-seller/safety",
    meta: safetyNP4YJjbCkAMeta || {},
    alias: safetyNP4YJjbCkAMeta?.alias || [],
    redirect: safetyNP4YJjbCkAMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-seller/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyNP4YJjbCkAMeta?.name ?? "account-seller-safety___en",
    path: safetyNP4YJjbCkAMeta?.path ?? "/en/account-seller/safety",
    meta: safetyNP4YJjbCkAMeta || {},
    alias: safetyNP4YJjbCkAMeta?.alias || [],
    redirect: safetyNP4YJjbCkAMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-seller/safety.vue").then(m => m.default || m)
  },
  {
    name: statlXIUo6fX65Meta?.name ?? "account-seller-stat___ru",
    path: statlXIUo6fX65Meta?.path ?? "/account-seller/stat",
    meta: statlXIUo6fX65Meta || {},
    alias: statlXIUo6fX65Meta?.alias || [],
    redirect: statlXIUo6fX65Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-seller/stat.vue").then(m => m.default || m)
  },
  {
    name: statlXIUo6fX65Meta?.name ?? "account-seller-stat___en",
    path: statlXIUo6fX65Meta?.path ?? "/en/account-seller/stat",
    meta: statlXIUo6fX65Meta || {},
    alias: statlXIUo6fX65Meta?.alias || [],
    redirect: statlXIUo6fX65Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/account-seller/stat.vue").then(m => m.default || m)
  },
  {
    name: login6Y49Qe3BVbMeta?.name ?? "auth-login___ru",
    path: login6Y49Qe3BVbMeta?.path ?? "/auth/login",
    meta: login6Y49Qe3BVbMeta || {},
    alias: login6Y49Qe3BVbMeta?.alias || [],
    redirect: login6Y49Qe3BVbMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: login6Y49Qe3BVbMeta?.name ?? "auth-login___en",
    path: login6Y49Qe3BVbMeta?.path ?? "/en/auth/login",
    meta: login6Y49Qe3BVbMeta || {},
    alias: login6Y49Qe3BVbMeta?.alias || [],
    redirect: login6Y49Qe3BVbMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: payment3nm6arDBdcMeta?.name ?? "auth-payment___ru",
    path: payment3nm6arDBdcMeta?.path ?? "/auth/payment",
    meta: payment3nm6arDBdcMeta || {},
    alias: payment3nm6arDBdcMeta?.alias || [],
    redirect: payment3nm6arDBdcMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/auth/payment.vue").then(m => m.default || m)
  },
  {
    name: payment3nm6arDBdcMeta?.name ?? "auth-payment___en",
    path: payment3nm6arDBdcMeta?.path ?? "/en/auth/payment",
    meta: payment3nm6arDBdcMeta || {},
    alias: payment3nm6arDBdcMeta?.alias || [],
    redirect: payment3nm6arDBdcMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/auth/payment.vue").then(m => m.default || m)
  },
  {
    name: registration_45customerm0PMn7kbHQMeta?.name ?? "auth-registration-customer___ru",
    path: registration_45customerm0PMn7kbHQMeta?.path ?? "/auth/registration-customer",
    meta: registration_45customerm0PMn7kbHQMeta || {},
    alias: registration_45customerm0PMn7kbHQMeta?.alias || [],
    redirect: registration_45customerm0PMn7kbHQMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/auth/registration-customer.vue").then(m => m.default || m)
  },
  {
    name: registration_45customerm0PMn7kbHQMeta?.name ?? "auth-registration-customer___en",
    path: registration_45customerm0PMn7kbHQMeta?.path ?? "/en/auth/registration-customer",
    meta: registration_45customerm0PMn7kbHQMeta || {},
    alias: registration_45customerm0PMn7kbHQMeta?.alias || [],
    redirect: registration_45customerm0PMn7kbHQMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/auth/registration-customer.vue").then(m => m.default || m)
  },
  {
    name: registration_45sellerh3H4i6pw2gMeta?.name ?? "auth-registration-seller___ru",
    path: registration_45sellerh3H4i6pw2gMeta?.path ?? "/auth/registration-seller",
    meta: registration_45sellerh3H4i6pw2gMeta || {},
    alias: registration_45sellerh3H4i6pw2gMeta?.alias || [],
    redirect: registration_45sellerh3H4i6pw2gMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/auth/registration-seller.vue").then(m => m.default || m)
  },
  {
    name: registration_45sellerh3H4i6pw2gMeta?.name ?? "auth-registration-seller___en",
    path: registration_45sellerh3H4i6pw2gMeta?.path ?? "/en/auth/registration-seller",
    meta: registration_45sellerh3H4i6pw2gMeta || {},
    alias: registration_45sellerh3H4i6pw2gMeta?.alias || [],
    redirect: registration_45sellerh3H4i6pw2gMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/auth/registration-seller.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordxjbKLbTaT9Meta?.name ?? "auth-reset-password___ru",
    path: reset_45passwordxjbKLbTaT9Meta?.path ?? "/auth/reset-password",
    meta: reset_45passwordxjbKLbTaT9Meta || {},
    alias: reset_45passwordxjbKLbTaT9Meta?.alias || [],
    redirect: reset_45passwordxjbKLbTaT9Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordxjbKLbTaT9Meta?.name ?? "auth-reset-password___en",
    path: reset_45passwordxjbKLbTaT9Meta?.path ?? "/en/auth/reset-password",
    meta: reset_45passwordxjbKLbTaT9Meta || {},
    alias: reset_45passwordxjbKLbTaT9Meta?.alias || [],
    redirect: reset_45passwordxjbKLbTaT9Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: send_45reset_45password_45linkY1aGuoZhvtMeta?.name ?? "auth-send-reset-password-link___ru",
    path: send_45reset_45password_45linkY1aGuoZhvtMeta?.path ?? "/auth/send-reset-password-link",
    meta: send_45reset_45password_45linkY1aGuoZhvtMeta || {},
    alias: send_45reset_45password_45linkY1aGuoZhvtMeta?.alias || [],
    redirect: send_45reset_45password_45linkY1aGuoZhvtMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/auth/send-reset-password-link.vue").then(m => m.default || m)
  },
  {
    name: send_45reset_45password_45linkY1aGuoZhvtMeta?.name ?? "auth-send-reset-password-link___en",
    path: send_45reset_45password_45linkY1aGuoZhvtMeta?.path ?? "/en/auth/send-reset-password-link",
    meta: send_45reset_45password_45linkY1aGuoZhvtMeta || {},
    alias: send_45reset_45password_45linkY1aGuoZhvtMeta?.alias || [],
    redirect: send_45reset_45password_45linkY1aGuoZhvtMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/auth/send-reset-password-link.vue").then(m => m.default || m)
  },
  {
    name: verify_45userCICNdktaqwMeta?.name ?? "auth-verify-user___ru",
    path: verify_45userCICNdktaqwMeta?.path ?? "/auth/verify-user",
    meta: verify_45userCICNdktaqwMeta || {},
    alias: verify_45userCICNdktaqwMeta?.alias || [],
    redirect: verify_45userCICNdktaqwMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/auth/verify-user.vue").then(m => m.default || m)
  },
  {
    name: verify_45userCICNdktaqwMeta?.name ?? "auth-verify-user___en",
    path: verify_45userCICNdktaqwMeta?.path ?? "/en/auth/verify-user",
    meta: verify_45userCICNdktaqwMeta || {},
    alias: verify_45userCICNdktaqwMeta?.alias || [],
    redirect: verify_45userCICNdktaqwMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/auth/verify-user.vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index___ru",
    path: indexAoBMx6PnC1Meta?.path ?? "/",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index___en",
    path: indexAoBMx6PnC1Meta?.path ?? "/en",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: investorsDAxxJuzdMiMeta?.name ?? "investors___ru",
    path: investorsDAxxJuzdMiMeta?.path ?? "/investors",
    meta: investorsDAxxJuzdMiMeta || {},
    alias: investorsDAxxJuzdMiMeta?.alias || [],
    redirect: investorsDAxxJuzdMiMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/investors.vue").then(m => m.default || m)
  },
  {
    name: investorsDAxxJuzdMiMeta?.name ?? "investors___en",
    path: investorsDAxxJuzdMiMeta?.path ?? "/en/investors",
    meta: investorsDAxxJuzdMiMeta || {},
    alias: investorsDAxxJuzdMiMeta?.alias || [],
    redirect: investorsDAxxJuzdMiMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/investors.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wkBDbFQkHIMeta?.name ?? "news-slug___ru",
    path: _91slug_93wkBDbFQkHIMeta?.path ?? "/news/:slug()",
    meta: _91slug_93wkBDbFQkHIMeta || {},
    alias: _91slug_93wkBDbFQkHIMeta?.alias || [],
    redirect: _91slug_93wkBDbFQkHIMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wkBDbFQkHIMeta?.name ?? "news-slug___en",
    path: _91slug_93wkBDbFQkHIMeta?.path ?? "/en/news/:slug()",
    meta: _91slug_93wkBDbFQkHIMeta || {},
    alias: _91slug_93wkBDbFQkHIMeta?.alias || [],
    redirect: _91slug_93wkBDbFQkHIMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAqnIhg14ssMeta?.name ?? "news___ru",
    path: indexAqnIhg14ssMeta?.path ?? "/news",
    meta: indexAqnIhg14ssMeta || {},
    alias: indexAqnIhg14ssMeta?.alias || [],
    redirect: indexAqnIhg14ssMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexAqnIhg14ssMeta?.name ?? "news___en",
    path: indexAqnIhg14ssMeta?.path ?? "/en/news",
    meta: indexAqnIhg14ssMeta || {},
    alias: indexAqnIhg14ssMeta?.alias || [],
    redirect: indexAqnIhg14ssMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: sellerlX3io9K2mKMeta?.name ?? "seller___ru",
    path: sellerlX3io9K2mKMeta?.path ?? "/seller",
    meta: sellerlX3io9K2mKMeta || {},
    alias: sellerlX3io9K2mKMeta?.alias || [],
    redirect: sellerlX3io9K2mKMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/seller.vue").then(m => m.default || m)
  },
  {
    name: sellerlX3io9K2mKMeta?.name ?? "seller___en",
    path: sellerlX3io9K2mKMeta?.path ?? "/en/seller",
    meta: sellerlX3io9K2mKMeta || {},
    alias: sellerlX3io9K2mKMeta?.alias || [],
    redirect: sellerlX3io9K2mKMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/seller.vue").then(m => m.default || m)
  }
]