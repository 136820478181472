<script setup>
const configStore = useConfigStore()
await useAsyncData('config', () => configStore.getEnv())

const { locale } = useI18n()
useHead({
  htmlAttrs: {
    lang: locale
  }
})
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
