import { jwtDecode, type JwtPayload} from "jwt-decode"

export const useJwtDecode = (token: string | null, field: string) => {
  interface Decoded extends JwtPayload {
    [key: string]: any
  }
  if (token) {
    try {
      const decoded = jwtDecode<Decoded>(token)
        return decoded[field]
      } catch (InvalidTokenError) {
        return InvalidTokenError
      }
  } else return null
}