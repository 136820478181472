export const appHead = {"meta":[{"charset":"utf-8"},{"hid":"viewport","name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Виджет с реальными планировками квартир: для автоматического расчёта стоимости напольных материалов"},{"hid":"og:url","property":"og:url","content":"https://m3.sale"},{"hid":"og:title","property":"og:title","content":"M3.SALE"},{"hid":"og:description","property":"og:description","content":"Виджет с реальными планировками квартир: для автоматического расчёта стоимости напольных материалов"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:image","property":"og:image","content":"/SCR-20240307-obfc.png"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/m3sale.ico"}],"style":[],"script":[{"hid":"gtm","innerHTML":"\n            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n            })(window,document,'script','dataLayer','GTM-WJLHX7S4');\n          ","type":"text/javascript","tagPosition":"head"}],"noscript":[{"innerHTML":"<iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-WJLHX7S4\"\n          height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>","tagPosition":"bodyOpen"}],"htmlAttrs":{"lang":"ru"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = false

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false