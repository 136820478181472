import { InvalidTokenError } from "jwt-decode"

export default defineNuxtRouteMiddleware((to) => {
  const token = useCookie<string | null>('access')
  const refresh = useCookie<string | null>('refresh')

  if (token.value && to.path.includes("auth")) {
    const userType = useJwtDecode(token.value, 'is_company_admin')
    if (!(userType instanceof InvalidTokenError)) {
      if (userType) {
        return navigateTo('/account-seller/organization')
      }
      if (userType === false) {
        return navigateTo('/account-customer/layouts')
      }
    } 
  }

  if (!token.value && to.path.includes('account')) {
    refresh.value = null
    return navigateTo('/auth/login')
  }
})